// All global styles goes here
@import "src/styles/palette";
@import "src/styles/variables";
/* Variables ---------------------------------------------------------------- */

/* Root, Scrollbar ---------------------------------------------------------- */
div#root {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* hiding scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
div::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@media screen and (max-width: 600px) {
  body { padding-right: 0; }
}

/* Info table --------------------------------------------------------------- */
.info-table {
  font-size: 16px;
  border-spacing: 0;
}
.info-table td, .info-table th {
  text-align: left;
  padding: 6px 8px;
  border: $borderLight;
  border-style: solid none none solid;
  &:last-child { border-right: $borderLight; }
}
.info-table thead tr {
  & > th { background-color: var(--colorLavenderL700); }
  & > th:first-child { border-top-left-radius: 6px; }
  & > th:last-child { border-top-right-radius: 6px; }
}
.info-table tr:last-child {
  & > td { border-bottom-style: solid }
  & > td:first-child { border-bottom-left-radius: 6px; }
  & > td:last-child { border-bottom-right-radius: 6px; }
}
.info-table tr:nth-child(2n) {
  background-color: var(--colorMentholM700);
}
.info-table tr:nth-child(2n+1) {
  background-color: var(--colorMentholM800);
}

/* Text --------------------------------------------------------------------- */
.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Autofill chrome fix ------------------------------------------------------ */
/* high transition time prevents ugly Chrome blue autofill bg to appear */
input:-webkit-autofill { transition: all 0s 86400s; }

/* Autocomplete ------------------------------------------------------------- */
.autocomplete-error .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--colorMuiError);
}
.autocomplete-error .MuiFormLabel-root.MuiInputLabel-root {
  color: var(--colorMuiError);
}

/* Other -------------------------------------------------------------------- */
.pointer { cursor: pointer }

/* List action menu --------------------------------------------------------- */
#list-actions-menu .MuiMenu-paper.MuiPaper-root  {
  background-color: var(--colorLavenderL700);
}
#list-actions-menu {
  .MuiMenu-paper {
    .MuiMenuItem-root {
      font-size: 14px;
      line-height: 1.2;
      padding: 6px 10px;
      min-width: 100px;
    }
  }
}

/* List json dialog --------------------------------------------------------- */
.json-data-dialog::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.json-data-dialog {
  font-family: 'Courier New', monospace;
  word-break: break-all;
  font-weight: 600;
  color: var(--colorText);
  overflow-x: scroll;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  pointer-events: auto;
  width: 900px;
  padding: 10px;
  background-color: var(--colorNeutralN900);
}

@media screen and (max-width: 1100px) {
  .json-data-dialog { width: 700px; }
}
@media screen and (max-width: 800px) {
  .json-data-dialog { width: 500px; }
}
@media screen and (max-width: 600px) {
  .json-data-dialog { width: 360px; }
}